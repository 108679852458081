<template>
  <div>
    <b-row>
      <b-col cols="12" md="6">
        <div class="int-kargo-fisi" v-if="Object.keys(firma).length">
          <h3 class="int-kargo-title font-weight-bold">GÖNDEREN</h3>
          <div class="int-kargo-desc">
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">AD SOYAD</h5>
              <h5>{{ firma.yetkili_ad_soyad }}</h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">ADRES</h5>
              <h5>
                {{ firma.adres }}
              </h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">TELEFON :</h5>
              <h5>{{ firma.telefon }}</h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">E POSTA:</h5>
              <h5>{{ firma.e_mail }}</h5>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <div class="int-kargo-fisi" v-if="Object.keys(form).length > 0">
          <h3 class="int-kargo-title font-weight-bold">ALICI</h3>
          <div class="int-kargo-desc">
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">AD SOYAD</h5>
              <h5>{{ form.uye_ad }} {{ form.uye_soyad }}</h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">ADRES</h5>
              <h5>
                {{ form.adres_bilgileri.kargo.adres }} - {{ form.adres_bilgileri.kargo.ilce }} /
                {{ form.adres_bilgileri.kargo.sehir }} / {{ form.adres_bilgileri.kargo.ulke }}
              </h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">TELEFON 1:</h5>
              <h5>{{ form.adres_bilgileri.kargo.telefon_1 }}</h5>
            </div>
            <div class="d-flex">
              <h5 class="pr-4 text-dark font-weight-bold">TELEFON 2:</h5>
              <h5>{{ form.adres_bilgileri.kargo.telefon_2 }}</h5>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { useRouter } from '@/libs/utils';
import store from '@/store';
import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  setup() {
    const expo = {};
    const { route } = useRouter();

    expo.form = ref({});
    expo.firma = ref({});

    onMounted(async () => {
      const k_no = route.value.params.k_no;
      if (k_no != null) {
        await store.dispatch('firmaBilgiGetir').then((res) => {
          if (res.data.data !== null) {
            expo.firma.value = res.data.data;
          }
        });
        await store.dispatch('eTicSiparisFindOne', k_no).then((res) => {
          expo.form.value = res;
        });
      }

      if (Object.keys(expo.firma.value).length > 0 && Object.keys(expo.form.value).length > 0) window.print();
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
@media print {
  @page {
    size: A4 landscape;
  }
}
.int-kargo-fisi {
  .int-kargo-title {
    border-bottom: 4px solid rgb(60, 60, 60);
  }
}
</style>
